import {
	type LoaderFunctionArgs,
	json,
	redirect,
	type MetaFunction,
	type LinksFunction,
} from "@remix-run/node"
import { Icon, type IconName } from "#app/components/ui/icon"
import { APP_NAME } from "#app/constants.ts"
import { getUserId } from "#app/utils/auth.server"

export async function loader({ request }: LoaderFunctionArgs) {
	const userId = await getUserId(request)
	if (userId) {
		return redirect("/unread")
	}
	return json({})
}

export function meta(): ReturnType<MetaFunction> {
	return [{ title: APP_NAME }]
}

export function links(): ReturnType<LinksFunction> {
	return [{ rel: "preload", as: "image", href: "/img/homepage.jpg" }]
}

interface SectionProps {
	title: React.ReactNode
	icon: IconName
	children: React.ReactNode
}

function Section({ title, children, icon }: SectionProps) {
	return (
		<div className="flex flex-col gap-4 p-3">
			<div className="flex items-center gap-4">
				<Icon name={icon} size="xl" className="text-primary" />
				<h2 className="text-2xl font-bold">{title}</h2>
			</div>
			{children}
		</div>
	)
}

export default function HomePage() {
	return (
		<div className="h-full w-full overflow-y-auto px-4 sm:px-6">
			<div className="mx-auto max-w-7xl pb-24">
				<div className="my-12 md:my-20 lg:my-20 lg:flex lg:items-center lg:gap-8">
					<div className="mx-auto basis-1/2 pb-10 text-center lg:pb-0 lg:text-left">
						<h1 className="mb-4 text-6xl font-bold tracking-tighter">
							A place to read,
							<br />
							<span className="text-primary">think, and share</span>
						</h1>
						<div className="mx-auto max-w-2xl text-balance lg:max-w-none">
							<p className="mb-6 text-2xl">
								Save articles to read later. Highlight and annotate text. Share your
								thoughts with the world.
							</p>
						</div>
					</div>
					<div className="basis-1/2">
						<div className="m-auto max-w-3xl">
							<img
								alt=""
								src="/img/homepage.jpg"
								className="h-auto w-full rounded-lg shadow-lg"
							/>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-4">
					<Section title="Save & Organize" icon="file-text">
						<p className="text-xl">
							Easily save articles to read later and keep your reading list organized
							with labels, favorites, and archives.
						</p>
					</Section>

					<Section title="Engage & Reflect" icon="pencil-1">
						<p className="text-xl">
							Highlight phrases and fragments that stand out to you.
						</p>
						<p className="text-xl">
							Take notes directly on your highlights to capture your thoughts and
							reflections as you read.
						</p>
					</Section>

					<Section title="Share & Inspire" icon="share">
						<p className="text-xl">
							Share articles with the world, including your highlights and notes.
						</p>
						<p className="text-xl">
							Inspire others with your insights and discover what your friends are
							reading and thinking.
						</p>
					</Section>

					<Section title="Read anywhere" icon="send">
						<p className="text-xl">
							Read articles on the go with our mobile-friendly design.
						</p>
						<p className="text-xl">
							Or send them to your email, Kindle, or other reading devices.
						</p>
					</Section>

					<Section title="Powerful search" icon="magnifying-glass">
						<p className="text-xl">
							Quickly find what you need across articles, highlights, and notes.
						</p>
						<p className="text-xl">
							Locate relevant passages, insights, and annotations with ease,
							streamlining your research and knowledge retrieval process.
						</p>
					</Section>

					<Section title="Customizable" icon="letter-case">
						<p className="text-xl">
							Tailor your reading experience with customizable font sizes and styles.
						</p>
						<p className="font-dyslexic text-lg">
							We also offer a Dyslexia and ADHD-friendly reading font for those who
							prefer it.
						</p>
					</Section>
				</div>
			</div>
		</div>
	)
}
